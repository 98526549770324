<template>
  <SfButton
    class="cart-icon sf-button--pure"
    aria-label="Cart"
    @click="toggleCartSidebar"
  >
    <span class="cart-icon--label">
      {{ $t('Cart') }}
    </span>
    <wm-icon
      name="cart-shopping"
      :label="$t('Cart')"
      :width="iconWidth"
      :height="iconHeight"
      class="cart-icon--icon"
    />
    <SfBadge
      v-if="cartTotalItems"
      class="sf-badge--number cart-icon--badge"
    >
      {{ cartTotalItems }}
    </SfBadge>
  </SfButton>
</template>
<script>
import {
  defineComponent,
  onMounted,
  computed
} from '@nuxtjs/composition-api'
import { SfButton, SfBadge } from '@storefront-ui/vue'
import { useCart } from '@wemade-vsf/customer';
import { useUiState } from '@wemade-vsf/composables';

export default defineComponent({
  name: 'CartIcon',
  props: {
    iconHeight: {
      type: String,
      default: '1.6875rem'
    },
    iconWidth: {
      type: String,
      default: '1.6875rem'
    }
  },
  components: {
    SfBadge,
    SfButton
  },
  setup () {
    const { toggleCartSidebar } = useUiState()
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0)

    onMounted (async () => {
      await loadCartTotalQty()
    })

    return {
      cartTotalItems,
      toggleCartSidebar
    }
  }
})
</script>
<style lang="scss" scoped>
.cart-icon {
  position: relative;
  height: 100%;
  &:not(.header-button) {
    --button-color: var(--main-menu-link);
  }

  &--label {
    display: var(--microcart-icon-text-display, var(--main-menu-icon-button-text-display, none));
    &:hover, &:active, &:focus {
      text-decoration: var(--microcart-icon-hover-text-decoration, var(--button-icon-hover-text-decoration, underline));
    }
  }
  &--icon {
    display: var(--microcart-icon-icon-display, var(--main-menu-icon-button-icon-display, inline-block));
    margin: var(--microcart-icon-margin, var(--button-icon-margin, 0));
  }
  &--badge {
    --badge-min-width: 1rem;
    --badge-min-height: 1rem;
    position: absolute;
    top: 0;
    right: -0.3rem;
    font-size: 0.6rem;
    padding: 0;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    color: var(--microcart-icon-badge-color, var(--c-primary));
    background: var(--microcart-icon-badge-bg, var(--c-white));
    @include for-desktop {
      top: 0.75rem;
    }
  }
  @include for-desktop {
    &--label {
      display: var(--microcart-icon-text-display, var(--main-menu-icon-button-text-display, inline-block));
    }
    &--icon {
      margin: var(--microcart-icon-margin, var(--button-icon-margin, 0 0 0 var(--spacer-xs)));
    }
  }
}

</style>